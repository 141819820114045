import * as React from 'react';
import classNames from 'classnames';
import ArticleCard, { IArticleCardProps } from '@/components/ui/ArticleCard';
import styles from './../styles.module.scss';

const CarouselPost: React.FunctionComponent<IArticleCardProps> = (props) => (
  <div className={classNames(styles['carousel__item'], styles['carousel__item-post'])}>
    <ArticleCard {...props} />
  </div>
);

CarouselPost.displayName = 'CarouselOpinion';

export default CarouselPost;
