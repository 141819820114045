'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import NonIdealState from '@/components/ui/NonIdealState';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

const DownloadLimitPaywall: React.FunctionComponent = () => {
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(OverlayKey.limit_download_paywall);
  const { t } = useTranslation();
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          <OverlayTitle onClose={closeOverlay} />
          <NonIdealState
            title={
              <Trans
                i18nKey={'components.paywall.downloadLimit'}
                t={t}
                components={{
                  a: <a />,
                }}
              />
            }
            imageUrl="/img/denied.png"
          />
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

DownloadLimitPaywall.displayName = 'DownloadLimitPaywall';

export default DownloadLimitPaywall;
