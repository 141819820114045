import * as React from 'react';
import { useTranslation } from 'react-i18next';
import HeadingWithTools from '@/components/ui/HeadingWithTools';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';

interface IProps {
  companyName: string;
  onBackClick: () => void;
}

export const NoCertificateResult: React.FC<IProps> = ({ companyName, onBackClick }) => {
  const { t } = useTranslation();
  return (
    <div className="align-center">
      <HeadingWithTools
        title={t('certificates.sorry')}
        description={t('certificates.notQualifies', { companyName: companyName })}
        level="h2"
      />

      <Buttons layout="centered">
        <Button
          title={t('certificates.backButton')}
          iconLeft="arrow02-left"
          theme="primary"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            onBackClick();
          }}
        />
      </Buttons>
    </div>
  );
};
