import { useTranslation } from 'react-i18next';
import Tabs from '@/components/ui/Tabs';

interface IProps {
  currentTab: number;
  setCurrentTab: (val: number) => void;
}

export const FilterTabs = (props: IProps) => {
  const { currentTab, setCurrentTab } = props;
  const { t } = useTranslation();
  return (
    <Tabs
      items={[
        {
          title: t('company.filter.personAudience'),
          href: '#',
          onClick: (e) => {
            e.preventDefault();
            setCurrentTab(1);
          },
          active: currentTab === 1,
        },
        {
          title: t('company.filter.companyAudience'),
          href: '#',
          onClick: (e) => {
            e.preventDefault();
            setCurrentTab(2);
          },
          active: currentTab === 2,
        },
      ]}
    />
  );
};
