import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ControlMulti from '@/components/ui/ControlMulti';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { InfopankCompanyTopFilter } from '@/apiTypes/types';
import { transformToControlMultiItem } from '@/components/helpers/searchOptionsToControlMultiItem';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';

interface IProps {
  form: IMarketingListToolFilter;
  tops?: InfopankCompanyTopFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  toggleItem: (arr: string[], item: string) => string[];
  layout: LayoutType;
  mobileButtons?: React.ReactNode;
}

export const CompanyTopRankings = (props: IProps) => {
  const { layout, form, setForm, toggleItem, mobileButtons, tops } = props;
  const companyTopsTree = React.useMemo(() => transformToControlMultiItem(tops || []), [tops]);
  const { t } = useTranslation();

  return (
    <ControlMulti
      id="companyTops"
      placeholder={t('company.filter.belongs')}
      values={form.companyTops}
      options={companyTopsTree}
      onChange={(value) => {
        setForm({
          ...form,
          companyTops: toggleItem(form.companyTops, value),
        });
      }}
      sameWidth={true}
      className="wide"
      layout={getLayout(layout)}
      mobileButtons={mobileButtons}
    />
  );
};
