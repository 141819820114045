'use client';

import * as React from 'react';
import Button from '@/components/ui/Button';
import { useClipboard } from '@/components/hooks/useClipboard';
import styles from './styles.module.scss';

const CopyText: React.FunctionComponent<{ text: string; labelCopy: string }> = (props) => {
  const { copyToClipboard } = useClipboard();
  return (
    <div className={styles['copy-text']}>
      <div className={styles['copy-text__text']}>{props.text}</div>
      <Button
        iconLeft="copy"
        size="small"
        theme="transparent"
        title={props.labelCopy}
        onClick={() => {
          copyToClipboard(props.text);
        }}
      />
    </div>
  );
};

CopyText.displayName = 'CopyText';

export default CopyText;
