import * as React from 'react';
import { Certificate } from '@/lib/dataProviders/fetchCompanyCertificates';
import { NoCertificateResult } from '@/components/content/CertificateSearch/results/NoCertificateResult';
import { ExistingCertificateResult } from '@/components/content/CertificateSearch/results/ExistingCertificateResult';

interface IProps {
  companyData: Certificate[] | null;
  companyName: string;
  onBackClick: () => void;
}

export const CertificateResults: React.FC<IProps> = (props) => {
  const { companyData, companyName, onBackClick } = props;
  if (!companyData) {
    return (
      <NoCertificateResult
        companyName={companyName}
        onBackClick={onBackClick}
      />
    );
  }

  return (
    <ExistingCertificateResult
      data={companyData}
      companyName={companyName}
      onBackClick={onBackClick}
    />
  );
};
