'use client';

import * as React from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import ButtonIcon from '@/components/ui/ButtonIcon';
import codeStyle from '@/components/ui/Code/codeStyle';
import { useClipboard } from '@/components/hooks/useClipboard';
import styles from './styles.module.scss';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('json', json);

export interface ICodeProps {
  code: string;
  language: 'bash' | 'json';
  labelCopy: string;
  labelWrap: string;
}

const Code: React.FunctionComponent<ICodeProps> = (props) => {
  const [wrapLongLines, setWrapLongLines] = React.useState(false);
  const { copyToClipboard } = useClipboard();
  return (
    <div className={styles['code']}>
      <div className={styles['code__tools']}>
        <div className={styles['code__tools__label']}>{props.language}</div>
        <div className={styles['code__tools__buttons']}>
          <ButtonIcon
            title={props.labelWrap}
            icon="wrap"
            size="tiny"
            theme="white-transparent"
            onClick={() => setWrapLongLines(!wrapLongLines)}
          />
          <ButtonIcon
            title={props.labelCopy}
            icon="copy"
            size="tiny"
            theme="white-transparent"
            onClick={() => {
              copyToClipboard(props.code);
            }}
          />
        </div>
      </div>
      <div className={styles['code__code']}>
        <SyntaxHighlighter
          language={props.language}
          style={codeStyle}
          useInlineStyles={true}
          showLineNumbers={false}
          wrapLines={true}
          wrapLongLines={wrapLongLines}
        >
          {props.code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

Code.displayName = 'Code';

export default Code;
