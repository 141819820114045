'use client';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Well from '@/components/ui/Well';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { companyRequestBody, personRequestBody } from '@/lib/helpers/marketingList';
import { downloadFile } from '@/lib/company/downloadFile';
import { User } from '@/components/auth/AuthProvider';
import { getDownloadUrl } from '@/lib/helpers/downloadMarketingList';
import { InfopankMarketingSearchLimits } from '@/apiTypes/types';
import { loadLimits } from '@/lib/marketingList/loadLimits';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { useOverlay } from '@/components/hooks/useOverlay';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

export type MarketingListExportType = 'Person' | 'Company';

interface IProps {
  total: number;
  user: User | null;
  filter: IMarketingListToolFilter;
  type: MarketingListExportType;
}

export const initialData: InfopankMarketingSearchLimits = {
  maxRowsInExport: 0,
  exportLimitType: 'None',
  maxAllowedExports: 0,
  totalExportsLeft: 0,
  totalExportsUsed: 0,
  exportPrice: 0,
};

export const getPayload = (type: MarketingListExportType, filter: IMarketingListToolFilter) => {
  return type === 'Person' ? personRequestBody(filter) : companyRequestBody(filter);
};

export const DownloadResults = (props: IProps) => {
  const { t } = useTranslation();
  const { openOverlay: openExtraDownloadOverlay } = useOverlay(OverlayKey.marketing_list_extra_download);
  const { openOverlay: openLimitDownloadOverlay } = useOverlay(OverlayKey.limit_download_paywall);
  const [loading, setLoading] = useState(false);
  const [orderCode, setOrderCode] = useState<string | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string>('#');
  const [limits, setLimits] = useState<InfopankMarketingSearchLimits>(initialData);

  useEffect(() => {
    const setData = async () => {
      setDownloadUrl(await getDownloadUrl(props.type));
      const orderCode = getCookie(CookieKey.orderCode);
      setOrderCode(orderCode);
    };
    void setData();
  }, [props.type]);

  useEffect(() => {
    const getLimits = async () => {
      const limits = await loadLimits(props.user, orderCode);
      setLimits(limits);
    };
    void getLimits();
  }, [loading, props.filter, props.user, orderCode]);

  return (
    <div>
      <Well theme="medium">
        <p className="text-size-large bold">{t('company.download.result', { total: props.total })}</p>
        <p>{t('company.download.description', { maxRowsInExport: limits.maxRowsInExport })}</p>
        <Buttons>
          <Button
            title={t('company.download.save')}
            iconLeft="document-download"
            theme="primary"
            size="large"
            loading={loading}
            disabled={loading}
            onClick={() => {
              if (limits.exportLimitType === 'UpperLimit' || limits.totalExportsLeft === 0) {
                openLimitDownloadOverlay();
              } else if (limits.exportLimitType === 'ExtraDownload') {
                openExtraDownloadOverlay();
              } else if (limits.totalExportsLeft > 0) {
                setLoading(true);
                void downloadFile(downloadUrl, props.user, 'POST', JSON.stringify(getPayload(props.type, props.filter))).finally(() => {
                  setLoading(false);
                });
              }
            }}
          />
        </Buttons>
      </Well>
      <p>
        {t('company.download.limit', {
          limit: limits.maxAllowedExports,
          stock: limits.totalExportsLeft,
        })}
      </p>
    </div>
  );
};
