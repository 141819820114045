import * as React from 'react';
import classNames from 'classnames';
import styles from '@/components/content/CertificateSearch/styles.module.scss';
import Highlighter from '@/components/ui/Highlighter';

export const CertificateCompanyRowResult: React.FunctionComponent<{
  highlight: boolean;
  keyword: string;
  label: string;
  url: string;
  onClick: (e: React.SyntheticEvent) => void;
}> = (props) => (
  <li>
    <a
      href={props.url}
      onClick={props.onClick}
      className={classNames({
        [styles['highlight']]: props.highlight,
      })}
    >
      <Highlighter
        text={props.label}
        search={props.keyword}
      />
    </a>
  </li>
);
