import { useState, useEffect, useCallback } from 'react';
import { loadInfopankArticles } from '@/lib/blog/loadInfopankArticles';
import { IArticleCardProps } from '@/components/ui/ArticleCard';
import { ArticleLink } from '@/apiTypes/types';

const mapArticleLinkToCard = (articles: ArticleLink[]): IArticleCardProps[] =>
  articles.map((article) => {
    const headline = article.headlinePlain;
    return {
      id: article.id,
      url: article.permalink,
      headline,
      image: article.image,
    };
  });

const useBlogPageArticles = (
  initialPagination: {
    total: number;
    limit: number;
    from: number;
  } = {
    total: 0,
    limit: 19,
    from: 0,
  },
) => {
  const [currentItems, setCurrentItems] = useState<IArticleCardProps[]>([]);
  const [currentPagination, setCurrentPagination] = useState(initialPagination);

  const fetchArticles = useCallback(async (from: number, limit: number) => {
    const blogArticles = await loadInfopankArticles({
      from,
      limit,
    });
    const items = mapArticleLinkToCard(blogArticles.articles);
    return {
      items,
      pagination: blogArticles.pagination,
    };
  }, []);

  useEffect(() => {
    const loadInitialArticles = async () => {
      const { items, pagination } = await fetchArticles(0, 19);
      setCurrentItems(items);
      setCurrentPagination(pagination);
    };
    void loadInitialArticles();
  }, [fetchArticles]);

  const loadMore = useCallback(
    async (from: number, limit: number) => {
      const { items, pagination } = await fetchArticles(from, limit);
      setCurrentItems(items);
      setCurrentPagination(pagination);
    },
    [fetchArticles],
  );

  return {
    currentItems,
    currentPagination,
    loadMore,
  };
};

export default useBlogPageArticles;
