const codeStyle: { [key: string]: React.CSSProperties } = {
  'code[class*="language-"]': {
    color: 'inherit',
    fontFamily: 'monospace',
    textAlign: 'left',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: 'inherit',
    tabSize: '4',
    hyphens: 'none',
    fontSize: 'inherit',
  },
  'pre[class*="language-"]': {
    color: 'inherit',
    fontFamily: 'monospace',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: 'inherit',
    tabSize: '4',
    hyphens: 'none',
    padding: 0,
    margin: 0,
    overflow: 'auto',
    borderRadius: 0,
    fontSize: 'inherit',
  },
  ':not(pre) > code[class*="language-"]': {
    padding: '0.1em',
    borderRadius: '0.3em',
    whiteSpace: 'normal',
    fontSize: 'inherit',
  },
  'comment': {
    color: '#d4d0ab',
  },
  'prolog': {
    color: '#d4d0ab',
  },
  'doctype': {
    color: '#d4d0ab',
  },
  'cdata': {
    color: '#d4d0ab',
  },
  'punctuation': {
    color: '#fefefe',
  },
  'property': {
    color: '#F92672',
  },
  'tag': {
    color: '#F92672',
  },
  'constant': {
    color: '#F92672',
  },
  'symbol': {
    color: '#F92672',
  },
  'deleted': {
    color: '#F92672',
  },
  'boolean': {
    color: '#66D9EF',
  },
  'number': {
    color: '#66D9EF',
  },
  'selector': {
    color: 'var(--color-success-200)',
  },
  'attr-name': {
    color: 'var(--color-success-200)',
  },
  'string': {
    color: 'var(--color-success-200)',
  },
  'char': {
    color: 'var(--color-success-200)',
  },
  'builtin': {
    color: 'var(--color-success-200)',
  },
  'inserted': {
    color: 'var(--color-success-200)',
  },
  'operator': {
    color: '#66D9EF',
  },
  'entity': {
    color: '#66D9EF',
    cursor: 'help',
  },
  'url': {
    color: '#66D9EF',
  },
  '.language-css .token.string': {
    color: '#66D9EF',
  },
  '.style .token.string': {
    color: '#66D9EF',
  },
  'variable': {
    color: '#66D9EF',
  },
  'atrule': {
    color: '#ffd700',
  },
  'attr-value': {
    color: '#ffd700',
  },
  'function': {
    color: '#ffd700',
  },
  'keyword': {
    color: '#66D9EF',
  },
  'regex': {
    color: '#ffd700',
  },
  'important': {
    color: '#ffd700',
    fontWeight: 'var(--bold)',
  },
  'bold': {
    fontWeight: 'var(--bold)',
  },
  'italic': {
    fontStyle: 'italic',
  },
};

export default codeStyle;
