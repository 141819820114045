'use client';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { User } from '@/components/auth/AuthProvider';
import Button from '@/components/ui/Button';
import Buttons from '@/components/ui/Buttons';
import { downloadFile } from '@/lib/company/downloadFile';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { getPayload, initialData, MarketingListExportType } from '@/components/content/MarketingListTool/DownloadResults';
import { getDownloadUrl } from '@/lib/helpers/downloadMarketingList';
import { loadLimits } from '@/lib/marketingList/loadLimits';
import { InfopankMarketingSearchLimits } from '@/apiTypes/types';

interface IProps {
  user: User | null;
  filter: IMarketingListToolFilter;
  type: MarketingListExportType;
}

const OverlayMarketingListExtraDownload: React.FC<IProps> = ({ user, type, filter }) => {
  const { t } = useTranslation();
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(OverlayKey.marketing_list_extra_download);
  const [loading, setLoading] = useState(false);
  const [orderCode, setOrderCode] = useState<string | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string>('#');
  const [limits, setLimits] = useState<InfopankMarketingSearchLimits>(initialData);

  useEffect(() => {
    const setData = async () => {
      setDownloadUrl(await getDownloadUrl(type));
      const orderCode = getCookie(CookieKey.orderCode);
      setOrderCode(orderCode);
    };
    void setData();
  }, [type]);

  useEffect(() => {
    const getLimits = async () => {
      const limits = await loadLimits(user, orderCode);
      setLimits(limits);
    };
    void getLimits();
  }, [loading, filter, user, orderCode]);

  const onClose = () => {
    closeOverlay();
  };

  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={onClose}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          <OverlayTitle onClose={onClose} />
          <p>
            <b>
              {t('report.extraDownload.description', {
                maxAllowed: limits.maxAllowedExports,
                price: limits.exportPrice,
              })}
            </b>
          </p>
          <Buttons>
            <Button
              title={t('report.extraDownload.confirm')}
              theme="primary"
              size="large"
              loading={loading}
              disabled={loading}
              onClick={() => {
                setLoading(true);
                void downloadFile(downloadUrl, user, 'POST', JSON.stringify(getPayload(type, filter))).finally(() => {
                  setLoading(false);
                  void onClose();
                });
              }}
            />
            <Button
              title={t('report.extraDownload.close')}
              theme="secondary"
              size="large"
              onClick={onClose}
            />
          </Buttons>
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

export default OverlayMarketingListExtraDownload;
