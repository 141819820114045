'use client';

import * as React from 'react';
import Image from 'next/image';
import Button from '@/components/ui/Button';
import styles from './styles.module.scss';

export interface IListCategoriesItem {
  iconUrl: string;
  imageUrl: string;
  title: string;
  description: string;
  buttonTitle: string;
  href: string;
}

export interface IListCategoriesProps {
  items: IListCategoriesItem[];
}

const ListCategories: React.FunctionComponent<IListCategoriesProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-categories']}>
      {props.items.map((item) => (
        <li key={item.title}>
          <div className={styles['list-categories__icon']}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={item.iconUrl}
              alt={item.title}
              width={80}
              height={80}
            />
          </div>
          <div className={styles['list-categories__image']}>
            <Image
              src={item.imageUrl}
              alt={item.title}
              fill={true}
              sizes="50vw"
            />
          </div>
          <div className={styles['list-categories__content']}>
            <div className={styles['list-categories__title']}>{item.title}</div>
            <div className={styles['list-categories__description']}>{item.description}</div>
            <div className={styles['list-categories__button']}>
              <Button
                title={item.buttonTitle}
                theme="primary"
                size="large"
                href={item.href}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

ListCategories.displayName = 'ListCategories';

export default ListCategories;
