import { Certificate } from '@/lib/dataProviders/fetchCompanyCertificates';

export const generateBadges = (certificates: Certificate[]) => {
  return certificates.flatMap((certificate) => certificate.Images.map((image) => image.Url));
};

export const generateUrl = (certificate: Certificate) => {
  const certificateSlug = certificate.Slug || '';
  return `${process.env.NEXT_PUBLIC_SHOP_URL}/${certificateSlug}`;
};
