import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FormRow from '@/components/ui/FormRow';
import { CompanyFilterOptions } from '@/components/content/MarketingListTool/interfaces/companyFilterOptions.interface';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { CompanyEmtaks } from '@/components/content/MarketingListTool/form/CompanyEmtaks';
import { CompanyLocations } from '@/components/content/MarketingListTool/form/CompanyLocations';
import { CompanyLegalForms } from '@/components/content/MarketingListTool/form/CompanyLegalForms';
import { CompanyEmployee } from '@/components/content/MarketingListTool/form/CompanyEmployee';
import { CompanySalesRevenue } from '@/components/content/MarketingListTool/form/CompanySalesRevenue';
import { CompanyProfitLoss } from '@/components/content/MarketingListTool/form/CompanyProfitLoss';
import { CompanyEstablishmentDate } from '@/components/content/MarketingListTool/form/CompanyEstablishementDate';
import { CompanyTopRankings } from '@/components/content/MarketingListTool/form/CompanyTopRankings';

export type LayoutType = 'desktop' | 'mobile';

interface IProps {
  form: IMarketingListToolFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  toggleItem: (arr: string[], item: string) => string[];
  currentTab: number;
  layout: LayoutType;
  mobileButtons?: React.ReactNode;
  company: CompanyFilterOptions;
}

export const GeneralFilter = (props: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <FormRow label={props.currentTab === 1 ? t('company.filter.relatedToPerson') : t('company.filter.listOfCompanies')}>
      <CompanyEmtaks
        emtaks={props.company.emtaks}
        layout={props.layout}
        form={props.form}
        setForm={props.setForm}
        toggleItem={props.toggleItem}
        mobileButtons={props.mobileButtons}
      />
      <CompanyLocations
        form={props.form}
        setForm={props.setForm}
        toggleItem={props.toggleItem}
        layout={props.layout}
        mobileButtons={props.mobileButtons}
        locations={props.company.locations}
      />
      <CompanyLegalForms
        form={props.form}
        setForm={props.setForm}
        layout={props.layout}
        toggleItem={props.toggleItem}
        mobileButtons={props.mobileButtons}
        legalForms={props.company.legalForms}
      />

      <CompanyEmployee
        form={props.form}
        setForm={props.setForm}
        layout={props.layout}
      />
      <CompanySalesRevenue
        layout={props.layout}
        form={props.form}
        setForm={props.setForm}
      />

      <CompanyProfitLoss
        layout={props.layout}
        form={props.form}
        setForm={props.setForm}
      />
      <CompanyEstablishmentDate
        layout={props.layout}
        form={props.form}
        setForm={props.setForm}
      />

      <CompanyTopRankings
        form={props.form}
        setForm={props.setForm}
        layout={props.layout}
        toggleItem={props.toggleItem}
        mobileButtons={props.mobileButtons}
        tops={props.company.tops}
      />
    </FormRow>
  );
};
