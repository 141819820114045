'use client';

import * as React from 'react';
import Button from '@/components/ui/Button';
import styles from './styles.module.scss';

export interface IListCollapsibleItem {
  id: string;
  iconUrl?: string;
  title: string;
  intro: React.ReactNode;
  details: React.ReactNode;
  defaultOpen?: boolean;
}

export interface IListCollapsibleProps {
  items: IListCollapsibleItem[];
  labelExpand: string;
  labelCollapse: string;
}

const ListCollapsibleItem: React.FunctionComponent<IListCollapsibleItem & Pick<IListCollapsibleProps, 'labelExpand' | 'labelCollapse'>> = (
  props,
) => {
  const [expanded, setExpanded] = React.useState(props.defaultOpen || false);
  return (
    <li className={expanded ? styles['is-expanded'] : undefined}>
      <div className={styles['list-collapsible__content']}>
        <div className={styles['list-collapsible__heading']}>
          {props.iconUrl && (
            <div className={styles['list-collapsible__icon']}>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src={props.iconUrl}
                alt={props.title}
                width={64}
                height={64}
              />
            </div>
          )}
          <div className={styles['list-collapsible__title']}>{props.title}</div>
        </div>
        <div className={styles['list-collapsible__description']}>{props.intro}</div>
        <div className={styles['list-collapsible__button']}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setExpanded(!expanded);
            }}
            title={expanded ? props.labelCollapse : props.labelExpand}
            iconRight={expanded ? 'arrow03-up' : 'arrow03-down'}
            theme="link"
          />
        </div>
      </div>
      {expanded && <div className={styles['list-collapsible__details']}>{props.details}</div>}
    </li>
  );
};

const ListCollapsible: React.FunctionComponent<IListCollapsibleProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-collapsible']}>
      {props.items.map((item) => (
        <ListCollapsibleItem
          labelCollapse={props.labelCollapse}
          labelExpand={props.labelExpand}
          key={item.id}
          {...item}
        />
      ))}
    </ul>
  );
};

ListCollapsible.displayName = 'ListCollapsible';

export default ListCollapsible;
