import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { InfopankCompanySearchRequestDto, InfopankLegalForms, InfopankPersonSearchRequestDto } from '@/apiTypes/types';

export const personRequestBody = (filter: IMarketingListToolFilter): InfopankPersonSearchRequestDto => ({
  roles: filter.roles
    .filter((r) => r !== 'all')
    .map((r) => ({
      discriminator: 'role',
      value: r,
    })),
  tradingLines: filter.tradingLines
    .filter((r) => r !== 'all')
    .map((tl) => ({
      discriminator: 'tradingLine',
      value: tl,
    })),
  tops: filter.tops
    .filter((r) => r !== 'all')
    .map((t) => ({
      discriminator: 'top',
      value: t,
    })),
  relatedCompany: companyRequestBody(filter),
});

export const companyRequestBody = (filter: IMarketingListToolFilter): InfopankCompanySearchRequestDto => ({
  emtaks: filter.companyEmtaks.map((ce) => ({
    discriminator: 'emtak',
    value: ce,
  })),
  tops: filter.companyTops.map((ct) => ({
    discriminator: 'top',
    value: ct,
  })),
  legalForms: filter.companyLegalForms.map((clf) => ({
    discriminator: 'legalForm',
    value: clf as InfopankLegalForms,
  })),
  locations: filter.companyLocations.map((cl) => ({
    discriminator: 'location',
    value: cl,
  })),
  employeeCount: {
    discriminator: 'employeeCount',
    min: filter.companyEmployee.min || null,
    max: filter.companyEmployee.max || null,
  },
  establishedOn: {
    discriminator: 'establishedOn',
    min: filter.companyEstablishmentDate.min || null,
    max: filter.companyEstablishmentDate.max || null,
  },
  profit: {
    discriminator: 'profit',
    min: filter.companyProfitLoss.min || null,
    max: filter.companyProfitLoss.max || null,
  },
  sales: {
    discriminator: 'sales',
    min: filter.companySalesRevenue.min || null,
    max: filter.companySalesRevenue.max || null,
  },
});
