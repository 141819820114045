import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlDate from '@/components/ui/ControlDate';
import ControlCustom from '@/components/ui/ControlCustom';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';

interface IProps {
  layout: LayoutType;
  form: IMarketingListToolFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
}

export const CompanyEstablishmentDate = (props: IProps) => {
  const { layout, form, setForm } = props;
  const { min = '', max = '' } = form?.companyEstablishmentDate || {};
  const { t } = useTranslation();
  return (
    <ControlCustom
      className="wide"
      placeholder={t('company.filter.establishDate')}
      hasValue={!!(min || max)}
      layout={getLayout(layout)}
    >
      <LayoutForm>
        <FormRow label={t('company.filter.from')}>
          <ControlDate
            value={min}
            onChange={(e) => {
              setForm({
                ...form,
                companyEstablishmentDate: {
                  min: e.currentTarget.value,
                  max: form?.companyEstablishmentDate.max ?? undefined,
                },
              });
            }}
          />
        </FormRow>
        <FormRow label={t('company.filter.to')}>
          <ControlDate
            value={max}
            onChange={(e) => {
              setForm({
                ...form,
                companyEstablishmentDate: {
                  min: form?.companyEstablishmentDate.min ?? undefined,
                  max: e.currentTarget.value,
                },
              });
            }}
          />
        </FormRow>
      </LayoutForm>
    </ControlCustom>
  );
};
