'use client';

import * as React from 'react';
import styles from './styles.module.scss';

export interface IListContactsProps {
  items: {
    title: string;
    details: React.ReactNode;
  }[];
}

const ListContacts: React.FunctionComponent<IListContactsProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-contacts']}>
      {props.items.map((item) => (
        <li key={item.title}>
          <div className={styles['list-contacts__title']}>{item.title}</div>
          <div className={styles['list-contacts__details']}>{item.details}</div>
        </li>
      ))}
    </ul>
  );
};

ListContacts.displayName = 'ListContacts';

export default ListContacts;
