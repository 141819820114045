import * as React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlInput from '@/components/ui/ControlInput';
import ControlCustom from '@/components/ui/ControlCustom';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';

interface IProps {
  layout: LayoutType;
  form: IMarketingListToolFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
}

export const CompanyEmployee = (props: IProps) => {
  const { layout, form, setForm } = props;
  const { min = '', max = '' } = form?.companyEmployee || {};
  const { t } = useTranslation();

  return (
    <ControlCustom
      placeholder={t('company.filter.numberEmployee')}
      hasValue={!!(min || max)}
      layout={getLayout(layout)}
    >
      <LayoutForm>
        <FormRow label={t('company.filter.from')}>
          <ControlInput
            type="number"
            value={min.toString()}
            onChange={(e) => {
              setForm({
                ...form,
                companyEmployee: {
                  ...form.companyEmployee,
                  min: parseInt(e.target.value, 10),
                },
              });
            }}
          />
        </FormRow>
        <FormRow label={t('company.filter.to')}>
          <ControlInput
            type="number"
            value={max.toString()}
            onChange={(e) => {
              setForm({
                ...form,
                companyEmployee: {
                  ...form.companyEmployee,
                  max: parseInt(e.target.value, 10),
                },
              });
            }}
          />
        </FormRow>
      </LayoutForm>
    </ControlCustom>
  );
};
