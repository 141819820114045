'use client';

export function useClipboard() {
  const isSupported = !!(
    navigator.clipboard ||
    (document &&
      typeof document.execCommand !== 'undefined' &&
      typeof document.queryCommandSupported !== 'undefined' &&
      typeof document.queryCommandSupported('copy') !== 'undefined')
  );

  async function copyClipboardApi(text: string) {
    return navigator.clipboard.writeText(text);
  }

  function copyExecCommand(text: string) {
    const span = document.createElement('span');
    span.textContent = text;

    span.style.whiteSpace = 'pre';
    span.style.userSelect = 'auto';
    span.style.userSelect = 'all';

    document.body.appendChild(span);

    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection && range) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      try {
        window.document.execCommand('copy');
      } finally {
        selection.removeAllRanges();
        window.document.body.removeChild(span);
      }
    }
  }

  async function clipboardCopy(text: string) {
    try {
      await copyClipboardApi(text);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err);
      try {
        copyExecCommand(text);
      } catch (err) {
        return err;
      }
    }
    return false;
  }

  return {
    isSupported,
    copyToClipboard: (value: string) => {
      if (isSupported) {
        void clipboardCopy(value);
      }
    },
  };
}
