import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ControlMulti from '@/components/ui/ControlMulti';
import FormRow from '@/components/ui/FormRow';
import { PersonFilterOptions } from '@/components/content/MarketingListTool/interfaces/personFilterOptions.interface';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';

interface IProps extends Omit<PersonFilterOptions, 'company'> {
  form: IMarketingListToolFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  toggleItem: (arr: string[], item: string) => string[];
  layout: LayoutType;
  mobileButtons?: React.ReactNode;
}

export const PersonFilter = (props: IProps): ReactElement => {
  const { t } = useTranslation();
  const { form, setForm, toggleItem, layout, mobileButtons } = props;

  const selectAllOptions = {
    label: t('company.filter.viewAll'),
    value: 'all',
  };

  return (
    <FormRow label={t('company.filter.keyPerson')}>
      <ControlMulti
        id="roles"
        placeholder={t('company.filter.relationship')}
        values={form.roles}
        options={[selectAllOptions, ...props.roles]}
        onChange={(value) => {
          if (value === 'all') {
            if (form.roles.includes('all')) {
              setForm({
                ...form,
                roles: [],
              });
            } else {
              setForm({
                ...form,
                roles: ['all', ...props.roles.map((v) => v.value)],
              });
            }
          } else {
            setForm({
              ...form,
              roles: toggleItem(form.roles, value),
            });
          }
        }}
        sameWidth={true}
        layout={getLayout(layout)}
        mobileButtons={mobileButtons}
      />
      <ControlMulti
        id="tradingLines"
        placeholder={t('company.filter.areaResponsibility')}
        values={form.tradingLines}
        options={[selectAllOptions, ...props.tradingLines]}
        onChange={(value) => {
          if (value === 'all') {
            if (form.tradingLines.includes('all')) {
              setForm({
                ...form,
                tradingLines: [],
              });
            } else {
              setForm({
                ...form,
                tradingLines: ['all', ...props.tradingLines.map((v) => v.value)],
              });
            }
          } else {
            setForm({
              ...form,
              tradingLines: toggleItem(form.tradingLines, value),
            });
          }
        }}
        sameWidth={true}
        layout={getLayout(layout)}
        mobileButtons={mobileButtons}
      />
      <ControlMulti
        id="tops"
        placeholder={t('company.filter.belongs')}
        values={form.tops}
        options={[selectAllOptions, ...props.tops]}
        onChange={(value) => {
          if (value === 'all') {
            if (form.tops.includes('all')) {
              setForm({
                ...form,
                tops: [],
              });
            } else {
              setForm({
                ...form,
                tops: ['all', ...props.tops.map((v) => v.value)],
              });
            }
          } else {
            setForm({
              ...form,
              tops: toggleItem(form.tops, value),
            });
          }
        }}
        sameWidth={true}
        layout={getLayout(layout)}
        mobileButtons={mobileButtons}
      />
    </FormRow>
  );
};
