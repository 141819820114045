'use client';

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadLatestInfopankArticles } from '@/lib/blog/loadLatestInfopankArticles';
import Carousel from '@/components/ui/Carousel';
import CarouselPost from '@/components/ui/Carousel/Post';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import { ArticleListResponse } from '@/apiTypes/types';
import { getLocalizedPath } from '@/components/helpers/localize';
import { Locale } from '@/lib/enums/locale.enum';

const LatestBlogArticles: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [latestArticles, setLatestArticles] = useState<ArticleListResponse['articles']>([]);
  const path = getLocalizedPath('blog', 'blog', i18n.language as Locale);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await loadLatestInfopankArticles();
        setLatestArticles(data.articles);
      } catch (error) {
        //
      }
    };

    void fetchArticles();
  }, []);

  return (
    <React.Fragment>
      <Carousel
        level="h2"
        title={t('components.blog.title')}
        layout="blog"
      >
        {latestArticles.map((article) => (
          <CarouselPost
            key={article.id}
            id={article.id}
            image={article.image}
            headline={article.headlinePlain}
            url={article.permalink}
          />
        ))}
      </Carousel>

      <Buttons layout="centered">
        <Button
          title={t('components.blog.button')}
          size="large"
          href={path}
        />
      </Buttons>
    </React.Fragment>
  );
};

export default LatestBlogArticles;
