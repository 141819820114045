import { useContext, useEffect, useRef, useState } from 'react';
import { loadCompanyFilters, loadPersonFilters } from '@/lib/marketingList/loadFilters';
import {
  InfopankCompanySearchOptionsResponseDto,
  InfopankCompanySearchResponseDto,
  InfopankPersonSearchOptionsResponseDto,
  InfopankPersonSearchResponseDto,
} from '@/apiTypes/types';
import { loadCompanyData, loadPersonData } from '@/lib/marketingList/loadData';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { DEBOUNCE_DELAY } from '@/lib/constants';
import { debounce } from '@/lib/helpers/debounce';
import { AuthContext } from '@/components/auth/AuthProvider';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';

const useMarketingListFilter = (currentTab: number, form: IMarketingListToolFilter) => {
  const [personFilters, setPersonFilters] = useState<InfopankPersonSearchOptionsResponseDto | null>(null);
  const [companyFilters, setCompanyFilters] = useState<InfopankCompanySearchOptionsResponseDto | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { user } = useContext(AuthContext);
  const [orderCode, setOrderCode] = useState<string | null>(null);

  const requestIdRef = useRef(0);

  const [personResult, setPersonResult] = useState<InfopankPersonSearchResponseDto>({
    items: [],
    meta: {
      total: 0,
      pageNumber: 0,
      pageSize: 50,
      links: [],
    },
  });
  const [companyResult, setCompanyResult] = useState<InfopankCompanySearchResponseDto>({
    items: [],
    meta: {
      total: 0,
      pageNumber: 0,
      pageSize: 50,
      links: [],
    },
  });

  useEffect(() => {
    const orderCode = getCookie(CookieKey.orderCode);
    setOrderCode(orderCode);
  }, [user]);

  // fetchFilter options
  useEffect(() => {
    if (currentTab === 1) {
      const fetchPersonFilters = async () => {
        const data = await loadPersonFilters();
        if (data) {
          setPersonFilters(data);
        }
      };
      void fetchPersonFilters();
    }
    if (currentTab === 2) {
      const fetchCompanyFilters = async () => {
        const data = await loadCompanyFilters();
        if (data) {
          setCompanyFilters(data);
        }
      };
      void fetchCompanyFilters();
    }
  }, [currentTab]);

  // fetchData
  useEffect(() => {
    const requestId = ++requestIdRef.current;

    const fetchData = async () => {
      // person
      if (currentTab === 1) {
        const data = await loadPersonData(form, currentPage, user, orderCode);

        if (requestId === requestIdRef.current && data) {
          setPersonResult(data);
          setCurrentPage(data.meta.pageNumber);
        }
      }

      if (currentTab === 2) {
        const data = await loadCompanyData(form, currentPage, user, orderCode);
        if (requestId === requestIdRef.current && data) {
          setCompanyResult(data);
          setCurrentPage(data.meta.pageNumber);
        }
      }
    };
    const debounceFetchData = debounce(fetchData, DEBOUNCE_DELAY);
    void debounceFetchData();

    return () => {
      debounceFetchData.cancel();
    };
  }, [currentTab, form, currentPage, user, orderCode]);

  return {
    personFilters,
    companyFilters,
    personResult,
    companyResult,
    setCurrentPage,
  };
};

export default useMarketingListFilter;
