import * as React from 'react';
import { useTranslation } from 'react-i18next';
import HeadingWithTools from '@/components/ui/HeadingWithTools';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import { CertificateInfo } from '@/components/content/CertificateSearch/CertificateInfo';
import { Certificate } from '@/lib/dataProviders/fetchCompanyCertificates';
import { generateBadges, generateUrl } from '@/lib/helpers/certificate';

interface IProps {
  data: Certificate[];
  companyName: string;
  onBackClick: () => void;
}

export const ExistingCertificateResult: React.FC<IProps> = (props) => {
  const { data, companyName, onBackClick } = props;
  const { t } = useTranslation();
  const [currentCertificate, setCurrentCertificate] = React.useState(data[0]);

  const generateDescription = (certificate: Certificate) => {
    if (!certificate.Description) {
      return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: certificate.Description }} />;
  };

  const handleImageClick = (index: number) => {
    const selectedCertificate = data[index];
    setCurrentCertificate(selectedCertificate);
  };

  const badges = generateBadges(data);
  const description = generateDescription(currentCertificate);
  const url = generateUrl(currentCertificate);

  return (
    <React.Fragment>
      <HeadingWithTools
        title={t('certificates.congratulations')}
        level="h2"
        description={t('certificates.qualifies', { companyName: companyName })}
        buttons={[
          {
            title: t('certificates.backButton'),
            iconLeft: 'arrow02-left',
            onClick: (e) => {
              e.preventDefault();
              onBackClick();
            },
            className: 'not-desktop-hide',
          },
        ]}
      />
      <CertificateInfo
        badges={badges}
        description={description}
        onImageClick={handleImageClick}
      />
      <Buttons>
        <Button
          title={t('certificates.order')}
          iconRight="out-going-link"
          theme="primary"
          size="large"
          href={url}
          target={'_blank'}
        />
        <Button
          title={t('certificates.backButton')}
          size="large"
          iconLeft="arrow02-left"
          className="desktop-hide"
          onClick={(e) => {
            e.preventDefault();
            onBackClick();
          }}
        />
      </Buttons>
    </React.Fragment>
  );
};
