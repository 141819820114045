'use client';

import * as React from 'react';
import { SetStateAction } from 'react';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import LayoutForm from '@/components/ui/LayoutForm';
import { useOverlay } from '@/components/hooks/useOverlay';
import { Overlays } from '@/components/ui/Overlays';
import { PersonFilter } from '@/components/content/MarketingListTool/PersonFilter';
import { GeneralFilter, LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';
import { FilterTabs } from '@/components/content/MarketingListTool/FilterTabs';
import { PersonResults } from '@/components/content/MarketingListTool/PersonResults';
import { CompanyResults } from '@/components/content/MarketingListTool/CompanyResults';
import useMarketingListFilter from '@/lib/hooks/useMarketingListFilter';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { PersonFilterOptions } from '@/components/content/MarketingListTool/interfaces/personFilterOptions.interface';
import { apiFilterOptionsToOption } from '@/components/helpers/apiFilterOptionsToOptions';
import { AuthContext } from '@/components/auth/AuthProvider';
import { UnAuthorizedResults } from '@/components/content/MarketingListTool/UnAuthorizedResults';
import MobileFilter from '@/app/[lang]/[access]/proto/teenused-ja-tooted/turundusnimekirjad/mobileFilter';
import OverlayMarketingListExtraDownload from '@/components/overlays/MarketingListExtraDownload';
import DownloadLimitPaywall from '@/components/overlays/DownloadLimitPaywall';
import { InfopankCompanyEmtakFilter, InfopankCompanyLocationFilter, InfopankPersonSearchOptionsResponseDto } from '@/apiTypes/types';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';

const filterInitialValues: IMarketingListToolFilter = {
  roles: [],
  tradingLines: [],
  tops: [],
  companyEmtaks: [],
  companyEmployee: {
    min: undefined,
    max: undefined,
  },
  companyLocations: [],
  companySalesRevenue: {
    min: undefined,
    max: undefined,
  },
  companyProfitLoss: {
    min: undefined,
    max: undefined,
  },
  companyLegalForms: [],
  companyTops: [],
  companyEstablishmentDate: {
    min: undefined,
    max: undefined,
  },
};

export interface IEmailLabels {
  emtaksLabels: string[];
  locationsLabels: string[];
}

const RenderForm: React.FunctionComponent<{
  currentTab: number;
  layout: LayoutType;
  form: IMarketingListToolFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  mobileButtons?: React.ReactNode;
  options: PersonFilterOptions;
}> = (props) => {
  const { currentTab, form, setForm, options } = props;

  const toggleItem = (arr: string[], item: string) =>
    arr.includes(item) ? arr.filter((i) => i !== item).filter((i) => i !== 'all') : [...arr, item];

  return (
    <LayoutForm
      layout="filter"
      className={props.layout === 'desktop' ? 'mobile-hide' : undefined}
      key={currentTab}
    >
      {currentTab === 1 && (
        <PersonFilter
          layout={props.layout}
          form={form}
          setForm={setForm}
          mobileButtons={props.mobileButtons}
          toggleItem={toggleItem}
          roles={options.roles}
          tradingLines={options.tradingLines}
          tops={options.tops}
        />
      )}
      <GeneralFilter
        layout={props.layout}
        form={form}
        setForm={setForm}
        currentTab={currentTab}
        mobileButtons={props.mobileButtons}
        toggleItem={toggleItem}
        company={{
          emtaks: options.company.emtaks,
          legalForms: options.company.legalForms,
          locations: options.company.locations,
          tops: options.company.tops,
        }}
      />
    </LayoutForm>
  );
};

const MarketingListTool: React.FunctionComponent = () => {
  const router = useRouter();
  const { openOverlay } = useOverlay(OverlayKey.filter);
  const { openOverlays } = React.useContext(Overlays);
  const { isOverlayOpen: isExtraDownloadOverlayOpen } = useOverlay(OverlayKey.marketing_list_extra_download);
  const { user } = React.useContext(AuthContext);
  const [currentTab, setCurrentTab] = React.useState(1);
  const [form, setForm] = React.useState<IMarketingListToolFilter>(filterInitialValues);
  const { personFilters, companyFilters, personResult, companyResult, setCurrentPage } = useMarketingListFilter(currentTab, form);
  const resultTotal = currentTab === 1 ? personResult.meta.total : companyResult.meta.total;
  const setFilterForm = (values: SetStateAction<IMarketingListToolFilter>) => {
    setCurrentPage(0);
    setForm(values);
  };
  const { t } = useTranslation();
  const findLabelRecursively = (tree: InfopankCompanyEmtakFilter | InfopankCompanyLocationFilter, value: string): string | null => {
    for (const option of tree) {
      if (option.filter?.value === value) {
        return option.label;
      }

      if (option.children && option.children.length > 0) {
        const label = findLabelRecursively(option.children, value);
        if (label) {
          return label;
        }
      }
    }
    return null;
  };

  const getLabelsFromPersonFilters = (
    personFilters: InfopankPersonSearchOptionsResponseDto | null,
    form: { companyEmtaks: string[]; companyLocations: string[] },
  ): IEmailLabels => {
    const result: { emtaksLabels: string[]; locationsLabels: string[] } = {
      emtaksLabels: [],
      locationsLabels: [],
    };

    if (!personFilters) {
      return result;
    }

    result.emtaksLabels = form.companyEmtaks
      .map((emtakValue) => findLabelRecursively(personFilters.company.emtaks, emtakValue) || null)
      .filter((label): label is string => label !== null);

    result.locationsLabels = form.companyLocations
      .map((locationValue) => findLabelRecursively(personFilters.company.locations, locationValue) || null)
      .filter((label): label is string => label !== null);

    return result;
  };

  const emailLabels = getLabelsFromPersonFilters(personFilters, form);
  const RenderMobileButtons = () => {
    return (
      <Buttons>
        <Button
          title={t('generic.actions.remove')}
          onClick={() => {
            setFilterForm(filterInitialValues);
          }}
        />
        <Button
          title={t('generic.actions.show')}
          theme="primary"
          onClick={() => {
            Array(openOverlays.length)
              .fill(0)
              .map(() => {
                router.back();
              });
          }}
        />
      </Buttons>
    );
  };

  const isPersonFilter = currentTab === 1;
  const options = {
    tops: apiFilterOptionsToOption(personFilters?.tops),
    roles: apiFilterOptionsToOption(personFilters?.roles),
    tradingLines: apiFilterOptionsToOption(personFilters?.tradingLines),
    company: {
      emtaks: isPersonFilter ? personFilters?.company.emtaks : companyFilters?.emtaks,
      legalForms: apiFilterOptionsToOption(isPersonFilter ? personFilters?.company.legalForms : companyFilters?.legalForms),
      locations: isPersonFilter ? personFilters?.company.locations : companyFilters?.locations,
      tops: isPersonFilter ? personFilters?.company.tops : companyFilters?.tops,
    },
  };
  return (
    <React.Fragment>
      <FilterTabs
        currentTab={currentTab}
        setCurrentTab={(val: number) => {
          setFilterForm(filterInitialValues);
          setCurrentTab(val);
        }}
      />

      <RenderForm
        layout="desktop"
        form={form}
        setForm={setFilterForm}
        currentTab={currentTab}
        options={options}
      />
      <Buttons
        layout="vertical-wide"
        className="not-mobile-hide"
      >
        <Button
          title={t('generic.actions.filter')}
          iconLeft="filter"
          onClick={openOverlay}
          size="large"
          theme="primary"
        />
      </Buttons>
      {!user ? (
        <UnAuthorizedResults
          total={resultTotal}
          form={form}
          isPersonFilter={isPersonFilter}
          formType="MARKETING"
          emailLabels={emailLabels}
        />
      ) : (
        <React.Fragment>
          {currentTab === 1 && (
            <PersonResults
              data={personResult}
              setCurrentPage={setCurrentPage}
              filter={form}
              user={user}
            />
          )}
          {currentTab === 2 && (
            <CompanyResults
              data={companyResult}
              setCurrentPage={setCurrentPage}
              filter={form}
              user={user}
            />
          )}
        </React.Fragment>
      )}
      <MobileFilter buttons={<RenderMobileButtons />}>
        <RenderForm
          layout="mobile"
          form={form}
          setForm={setFilterForm}
          currentTab={currentTab}
          mobileButtons={<RenderMobileButtons />}
          options={options}
        />
      </MobileFilter>
      {isExtraDownloadOverlayOpen && (
        <OverlayMarketingListExtraDownload
          user={user}
          filter={form}
          type={currentTab === 1 ? 'Person' : 'Company'}
        />
      )}
      <DownloadLimitPaywall />
    </React.Fragment>
  );
};

MarketingListTool.displayName = 'MarketingListTool';

export default MarketingListTool;
