import { Trans } from 'react-i18next';
import styles from '@/components/content/CertificateSearch/styles.module.scss';

export const CertificateDescription = () => {
  return (
    <div className={styles['certificate-search__description']}>
      <Trans
        i18nKey="certificates.description.html"
        components={{
          p: <p />,
          ul: <ul />,
          li: <li />,
        }}
      />
    </div>
  );
};
