import { InfopankCompanyEmtakFilter, InfopankCompanyLocationFilter, InfopankCompanyTopFilter } from '@/apiTypes/types';
import { IControlMultiItem } from '@/components/ui/ControlMulti';

export const transformToControlMultiItem = (
  options: InfopankCompanyEmtakFilter | InfopankCompanyLocationFilter | InfopankCompanyTopFilter,
): IControlMultiItem[] => {
  return options.slice().map((option, index) => {
    const value = option.filter?.value || `display-${index}`;

    return {
      label: option.label,
      value: value,
      options: transformToControlMultiItem(option.children),
    };
  });
};
