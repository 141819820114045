'use client';

import * as React from 'react';
import classNames from 'classnames';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

export interface IListFormulasProps {
  items: {
    a: string;
    b: string;
    c: string;
    theme: 'default' | 'yellow' | 'red' | 'blue';
  }[];
}

const ListFormulas: React.FunctionComponent<IListFormulasProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-formulas']}>
      {props.items.map((item, itemIndex) => (
        <li
          key={itemIndex}
          className={classNames({
            [styles['theme-default']]: item.theme === 'default',
            [styles['theme-red']]: item.theme === 'red',
            [styles['theme-yellow']]: item.theme === 'yellow',
            [styles['theme-blue']]: item.theme === 'blue',
          })}
        >
          <span className={styles['list-formulas__a']}>{item.a}</span>
          <span className={styles['list-formulas__b']}>{item.b}</span>
          <span className={styles['list-formulas__c']}>{item.c}</span>
          <span className={styles['list-formulas__d']}>
            <Icon
              width={24}
              height={24}
              kind="equals"
            />
          </span>
          <span className={styles['list-formulas__e']}>
            <Icon
              width={24}
              height={24}
              kind="divide"
            />
          </span>
        </li>
      ))}
    </ul>
  );
};

ListFormulas.displayName = 'ListFormulas';

export default ListFormulas;
