'use client';

import * as React from 'react';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

export interface IListLinksProps {
  items: {
    key: string;
    label: React.ReactNode;
    href: string;
  }[];
}

const ListLinks: React.FunctionComponent<IListLinksProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul className={styles['list-links']}>
      {props.items.map((item) => (
        <li key={item.key}>
          <a
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{item.label}</span>
            <Icon
              width={24}
              height={24}
              kind="arrow03-right"
            />
          </a>
        </li>
      ))}
    </ul>
  );
};

ListLinks.displayName = 'ListLinks';

export default ListLinks;
