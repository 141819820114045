import { IControlMultiItem } from '@/components/ui/ControlMulti';
import {
  InfopankCompanyLegalFormFilter,
  InfopankCompanyRoleFilter,
  InfopankCompanyTopFilter,
  InfopankCompanyTradingLineFilter,
} from '@/apiTypes/types';

export const apiFilterOptionsToOption = (
  options:
    | InfopankCompanyTopFilter
    | InfopankCompanyRoleFilter
    | InfopankCompanyTradingLineFilter
    | InfopankCompanyLegalFormFilter
    | undefined,
): IControlMultiItem[] => {
  if (!options || !options.length) {
    return [];
  }
  return options.map((o, i) => ({
    label: o.label,
    value: o.filter?.value || `${o.label}-${i}`,
  }));
};
