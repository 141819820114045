export const downloadFiles = (filePaths: string[]) => {
  filePaths.forEach((file) => {
    const link = document.createElement('a');
    link.href = file;
    link.setAttribute('download', file.split('/').pop() ?? 'download');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
