'use client';

import * as React from 'react';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SearchCompanyItem, SearchResponse } from '@/apiTypes/types';
import { SearchScope } from '@/lib/enums/searchScope';
import { useKeypress } from '@/components/hooks/useKeyPress';
import Button from '@/components/ui/Button';
import Well from '@/components/ui/Well';
import { CertificateCompanyRowResult } from '@/components/content/CertificateSearch/CertificateCompanyRowResult';
import { CertificateResults } from '@/components/content/CertificateSearch/CertificateResults';
import { Certificate, fetchCompanyCertificates } from '@/lib/dataProviders/fetchCompanyCertificates';
import { CertificateDescription } from '@/components/content/CertificateSearch/CertificateDescription';
import { loadAutocompleteSearch } from '@/lib/dataProviders/loadAutocompleteSearch';
import { SEARCH_MIN_LENGTH } from '@/lib/constants';
import { Locale } from '@/lib/enums/locale.enum';
import styles from './styles.module.scss';

const CertificateSearch: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const [hasFocus, setHasFocus] = React.useState(false);
  const [hasResultsFocus, setHasResultsFocus] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [highlightIndex, setHighlightIndex] = React.useState(-1);
  const searchField = React.useRef<HTMLInputElement>(null);
  const searchResults = React.useRef<HTMLDivElement>(null);
  const [result, setResult] = React.useState(0);
  const [results, setResults] = React.useState<SearchResponse>();
  const [companyData, setCompanyData] = React.useState<Certificate[] | null>(null);
  const [companyName, setCompanyName] = React.useState('');

  const search = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (keyword !== '') {
      await setSearchResults(keyword);
      setHasResultsFocus(true);
      setResult(highlightIndex + 1);
    }
  };

  const setSearchResults = async (keyword: string) => {
    const searchResult = await loadAutocompleteSearch(keyword, SearchScope.companies);
    setResults(searchResult);
  };

  const handleBackClick = () => {
    setResult(0);
    setCompanyData(null);
    setCompanyName('');
    setKeyword('');
  };

  const loadCompanyData = async (registrationCode: string, locale: Locale) => {
    return await fetchCompanyCertificates(registrationCode, locale);
  };

  useKeypress(
    'ArrowUp',
    () => {
      if (hasFocus) {
        setHighlightIndex(highlightIndex - 1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );
  useKeypress(
    'ArrowDown',
    () => {
      if (hasFocus) {
        setHighlightIndex(highlightIndex + 1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );
  useKeypress(
    'Escape',
    () => {
      if (hasFocus) {
        setKeyword('');
        setHighlightIndex(-1);
      }
    },
    [hasFocus, highlightIndex, setHighlightIndex],
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (target && searchResults.current && searchResults.current.contains(target as Node)) {
        setHasFocus(true);
        setHasResultsFocus(true);
        setTimeout(() => {
          if (searchField && searchField.current) {
            searchField.current.focus();
          }
        });
      } else {
        setHasResultsFocus(false);
      }
    };
    globalThis.addEventListener('mousedown', handleClickOutside);
    return () => {
      globalThis.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hasResultsFocus, searchResults]);

  const handleCompanySearchResult = async (e: SyntheticEvent<Element, Event>, item: SearchCompanyItem, index: number) => {
    e.preventDefault();
    const data = await loadCompanyData(item.registrationCode, i18n.language as Locale);
    setCompanyData(data);
    setCompanyName(item.name);
    setResult(index + 1);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void search(event);
  };

  const handleSearchResults = async (value: string) => {
    await setSearchResults(value);
  };

  return (
    <Well
      className={styles['certificate-search']}
      animationLeft="animation06"
      theme="light"
    >
      {result === 0 && (
        <React.Fragment>
          <h2>{t('certificates.companyQualifies')}</h2>
          <div className={styles['certificate-search__field']}>
            <div className={styles['certificate-search__field__header']}>
              <form
                className={classNames(styles['certificate-search__field__form'], { [styles['focus']]: hasFocus })}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <input
                  name="keyword"
                  type="text"
                  value={keyword}
                  autoComplete="off"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    void handleSearchResults(e.target.value);
                  }}
                  onFocus={() => {
                    setHasFocus(true);
                  }}
                  onBlur={() => {
                    setHasFocus(false);
                  }}
                  ref={searchField}
                  placeholder={t('components.search.placeholder')}
                  title={t('components.search.placeholder')}
                />
                <Button
                  title={t('components.search.startSearch')}
                  theme="primary"
                  type="submit"
                  size="large"
                />
              </form>
            </div>
            {keyword !== '' && keyword.length >= SEARCH_MIN_LENGTH && (hasFocus || hasResultsFocus) && (
              <div
                className={styles['certificate-search__field__results']}
                onMouseDown={() => {
                  setHasFocus(true);
                  setHasResultsFocus(true);
                }}
                ref={searchResults}
              >
                <ul className={styles['certificate-search__field__results__list']}>
                  {results &&
                    results.companies.items.length > 0 &&
                    results.companies.items.map((item, index) => (
                      <CertificateCompanyRowResult
                        key={item.id}
                        highlight={highlightIndex === index}
                        keyword={keyword}
                        label={item.name}
                        url="#"
                        onClick={(e) => void handleCompanySearchResult(e, item, index)}
                      />
                    ))}
                </ul>
              </div>
            )}
          </div>
          <CertificateDescription />
        </React.Fragment>
      )}
      {result > 0 && (
        <CertificateResults
          companyData={companyData}
          companyName={companyName}
          onBackClick={handleBackClick}
        />
      )}
    </Well>
  );
};

CertificateSearch.displayName = 'CertificateSearch';

export default CertificateSearch;
