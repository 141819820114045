import * as React from 'react';
import Image from 'next/image';
import styles from '@/components/content/CertificateSearch/styles.module.scss';

export const CertificateInfo: React.FunctionComponent<{
  badges: string[];
  description: React.ReactNode;
  onImageClick: (index: number) => void;
}> = (props) => {
  return (
    <div className={styles['certificate-search__info']}>
      {props.badges && props.badges.length > 0 && (
        <ul className={styles['certificate-search__info__badges']}>
          {props.badges.map((badge, i) => (
            <li key={i}>
              <Image
                src={badge}
                alt=""
                fill={true}
                onClick={() => props.onImageClick(i)}
                style={{ cursor: 'pointer' }}
              />
            </li>
          ))}
        </ul>
      )}
      <div className={styles['certificate-search__info__text']}>{props.description}</div>
    </div>
  );
};
