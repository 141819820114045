import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { InfopankCompanyLocationFilter } from '@/apiTypes/types';
import ControlMulti from '@/components/ui/ControlMulti';
import { transformToControlMultiItem } from '@/components/helpers/searchOptionsToControlMultiItem';
import { getLayout } from '@/lib/helpers/getLayout';
import { LayoutType } from '@/components/content/MarketingListTool/GeneralFilter';
import { findOption, getAllChildValues } from '@/components/content/MarketingListTool/form/CompanyEmtaks';

interface IProps {
  form: IMarketingListToolFilter;
  locations?: InfopankCompanyLocationFilter;
  setForm: React.Dispatch<React.SetStateAction<IMarketingListToolFilter>>;
  toggleItem: (arr: string[], item: string) => string[];
  layout: LayoutType;
  mobileButtons?: React.ReactNode;
}

export const CompanyLocations = (props: IProps) => {
  const { form, locations, setForm, toggleItem, layout, mobileButtons } = props;
  const companyLocationTree = React.useMemo(() => transformToControlMultiItem(locations || []), [locations]);
  const { t } = useTranslation();
  return (
    <ControlMulti
      id="companyLocations"
      placeholder={t('company.filter.location')}
      values={form.companyLocations}
      options={companyLocationTree}
      onChange={(value) => {
        const selectedOption = findOption(companyLocationTree, value);
        if (selectedOption && selectedOption.options) {
          const childValues = getAllChildValues(selectedOption.options);
          const isChecked = form.companyLocations.includes(value);
          let newValues;
          if (isChecked) {
            newValues = form.companyLocations.filter((v) => ![value, ...childValues].includes(v));
          } else {
            newValues = Array.from(new Set([...form.companyLocations, value, ...childValues]));
          }
          setForm({
            ...form,
            companyLocations: newValues,
          });
        } else {
          setForm({
            ...form,
            companyLocations: toggleItem(form.companyLocations, value),
          });
        }
      }}
      sameWidth={true}
      layout={getLayout(layout)}
      mobileButtons={mobileButtons}
    />
  );
};
