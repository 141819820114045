'use client';

import * as React from 'react';
import ListArticles from '@/components/ui/ListArticles';
import Paginator from '@/components/ui/Paginator';
import useBlogPageArticles from '@/lib/hooks/useBlogPageArticles';

type Props = {
  pagination: {
    total: number;
    limit: number;
    from: number;
  };
};

const BlogPageArticles: React.FunctionComponent<Props> = (props) => {
  const { currentItems, currentPagination, loadMore } = useBlogPageArticles(props.pagination);
  const numberOfPages = Math.ceil(currentPagination.total / currentPagination.limit);
  const currentPage = Math.floor(currentPagination.from / currentPagination.limit) + 1;

  const handlePageChangeAsync = async (selected: number) => {
    const selectedFrom = selected * currentPagination.limit;
    try {
      await loadMore(selectedFrom, currentPagination.limit);
    } catch (e) {
      //
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    void handlePageChangeAsync(selected);
  };

  return (
    <React.Fragment>
      <ListArticles items={currentItems} />
      <Paginator
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </React.Fragment>
  );
};

export default BlogPageArticles;
